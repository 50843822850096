/* You can add global styles to this file, and also import other style files */
html,
body {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

input{
  width: 100%;
}
